<template>
  <v-container
    id="issues"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="config.search.criteria"
          :disabled="config.search.loading"
          label="Like search"
          filled
          v-on:keyup.enter="search()"
          single-line
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="config.search.status"
          :disabled="config.search.loading"
          :items="config.statuses"
          filled
          label="Status"
        />
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="from"
          v-model="config.search.fromModel"
          :return-value.sync="config.search.from"
          :disabled="config.search.loading"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="config.search.from"
              label="Date From"
              prepend-icon="mdi-calendar"
              :disabled="config.search.loading"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="config.search.from"
            :max="config.search.to"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="config.search.fromModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.from.save(config.search.from)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="to"
          v-model="config.search.toModel"
          :return-value.sync="config.search.to"
          :disabled="config.search.loading"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="config.search.to"
              :disabled="config.search.loading"
              label="Date To"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="config.search.to"
            :min="config.search.from"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="config.search.toModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.to.save(config.search.to)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <v-btn
          :loading="config.search.loading"
          :disabled="config.search.loading"
          color="primary"
          class="white--text btn-center"
          x-large
          @click="search()"
        >
          Search
          <v-icon
            dark
            right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="config.headers"
      :items="issues"
      :items-per-page="config.pagination.size"
      :loading="config.search.loading"
      :disable="config.search.loading"
      loading-text="Loading... Please wait"
      class="elevation-1 row-pointer"
      @click:row="clickRow"
    >
      <template v-slot:[`item.status`]="{ item }">
        <p v-if="item.status === 'NEW'" class="green--text">
          {{item.status}}
        </p>
        <p v-else-if="item.status === 'IN_PROGRESS'" class="purple--text">
          {{item.status}}
        </p>
        <p v-else>
          {{item.status}}
        </p>
      </template>
    </v-data-table>
    <v-row class="mt-5">
      <v-col cols="2">
        <v-btn
          color="primary"
          class="white--text btn-center"
          x-large
          target="_blank"
          @click="newIssue()"
        >
          New Issue
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import axios from 'axios'
import DateTimeService from '@/service/DateTimeService'

export default {
  name: 'IssuesList',
  data: () => ({
    config: {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Subject', value: 'subject' },
        { text: 'Short Description', value: 'shortDescription' },
        { text: 'Status', value: 'status' },
        { text: 'Created By', value: 'userName' },
        { text: 'Created At', value: 'createAtFormat' },
        { text: 'Updated At', value: 'updateAtFormat' }
      ],
      statuses: [
        { text: 'New', value: 'NEW' },
        { text: 'In Progress', value: 'IN_PROGRESS' },
        { text: 'Done by Support', value: 'DONE_BY_SUPPORT' },
        { text: 'Finish', value: 'FINISH' }
      ],
      pagination: {
        page: 0,
        size: 10
      },
      search: {
        criteria: '',
        status: '',
        loading: false,
        from: '',
        to: '',
        fromModel: false,
        toModel: false
      }
    },
    issues: []
  }),
  beforeMount () {
    this.fetchIssues()
  },
  methods: {
    async clickRow ({ id }) {
      const category = this.$route.params.category
      await this.$router.push(`/support/${category}/issue/${id}`)
    },
    async newIssue () {
      const category = this.$route.params.category
      await this.$router.push(`/support/${category}/issue/-1`)
    },
    async search () {
      await this.fetchIssues()
    },
    async fetchIssues () {
      // state
      this.config.search.loading = true
      this.issues = []

      // request
      const category = this.$route.params.category
      let query = `page=${this.config.pagination.page}&size=${this.config.pagination.size}&categoryKey=${category}`
      if (this.config.search.criteria) {
        query += `&criteria=${encodeURIComponent(this.config.search.criteria)}`
      }
      if (this.config.search.status) {
        query += `&status=${this.config.search.status}`
      }
      if (this.config.search.from) {
        query += `&from=${new Date(this.config.search.from).getTime()}`
      }
      if (this.config.search.to) {
        query += `&to=${new Date(this.config.search.to).getTime()}`
      }
      axios.get(`/api/v1/support/issues/criteria?${query}`)
        .then(r => {
          console.log(r)
          this.issues = r.data.content
          this.issues.forEach(item => {
            item.createAtFormat = ''
            item.updateAtFormat = ''
            if (item.updateAt != null) {
              item.updateAtFormat = DateTimeService.format(new Date(item.updateAt))
            }
            if (item.createAt != null) {
              item.createAtFormat = DateTimeService.format(new Date(item.createAt))
            }
          })
          this.config.search.loading = false
        })
        .catch(e => {
          console.log(e)
          this.issues = []
          this.config.search.loading = false
        })
    }
  }
}
</script>
<style scoped>
.full-height {
  height: 100%;
}

.row-pointer :hover {
  cursor: pointer;
}
</style>
